<template>
  <div class="pageContent">
    <searchHeader />
    <div class="newsCenter">
      <el-row>
        <div class="centerLeft">
          <el-col :span="2">
            <div class="centerLeftItem">
              <el-row>
                <el-col :span="24">
                  <p class="centerTitle">资讯</p>
                </el-col>
                <el-col :span="24" v-for="item in cloumnDatas" :key="item.id">
                  <p
                    class="centerItem"
                    :class="item.id == activeCloumnId ? 'activeItem' : ''"
                    @click="chooseCloumn(item.id, item.name)"
                  >{{ item.name }}</p>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </div>
        <el-col :span="20" :offset="1">
          <newsList ref="newsList" />
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import searchHeader from "../base/header/searchHeader";
import newsList from "./subpage/newsList";
export default {
  name: "news",
  data() {
    return {
      cloumnId: null,
      cloumnDatas: [
        // {
        //   id: window.c.industryNews,
        //   name: "行业资讯"
        // },
        // {
        //   id: window.c.plateNews,
        //   name: "平台资讯"
        // }
      ],
      activeCloumnId:null
      // activeCloumnId: window.c.industryNews
    };
  },
  components: {
    newsList,
    searchHeader
  },
  mounted() {
    this.getNewsList()
    this.getTopCloumn();
  },
  methods: {
     getNewsList(){
      protocolFwd.param_getContentLowerColumn.param.parentColumnId = window.c.newsList;
      protocolFwd.param_getContentLowerColumn.param.page = 0;
      protocolFwd.param_getContentLowerColumn.param.size = 100;
      http.getRes(protocolFwd.param_getContentLowerColumn).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.cloumnDatas = value.content;
          this.activeCloumnId = value.content[0].id;
          this.getTopCloumn(value.content[0].id);
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getTopCloumn(id) {
      if(id==undefined||id==''||id==null){
        return
      }
      this.$refs.newsList.getNewsListDatas(id);
    },
    chooseCloumn(id, name) {
      this.activeCloumnId = id;
      this.$refs.newsList.getNewsListDatas(this.activeCloumnId, name);
    }
  }
};
</script>
<style lang="scss" scoped>
.newsCenter {
  padding: 15px 0;
  width: 1200px;
  // width: 80%;
  // min-width: 1300px;
  min-height: 1000px;
  margin: 10px auto;
  background-color: #ffffff;
  // border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.newsCenter .centerLeft {
  line-height: 40px;
  text-align: center;
}
.newsCenter .centerLeftItem {
  margin-bottom: 20px;
}
.newsCenter .centerTitle {
  font-size: 16px;
}
.newsCenter .centerItem {
  font-size: 14px;
  color: #9999a2;
  cursor: pointer;
}
.activeItem {
  color: $commonThemeColor !important;
}
</style>
