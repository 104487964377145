<template>
  <div class="newsListPage subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>{{ activeCloumnName }}</p>
      </div>
    </div>
    <div class="subPageContainer">
      <div class="newsList">
        <div
          class="newsItem"
          v-for="item in newsDatas"
          :key="item.id"
          @click="goNewsDetails(item.id)"
        >
          <div class="newsImage">
            <img :src="item.titleImg">
          </div>
          <div class="newsContent">
            <p class="newsTitle">{{ item.title }}</p>
            <p class="newsDesc">{{ item.subtitle }}</p>
            <p class="newsRemark">来源：{{ item.source }} 时间：{{ item.createTime | DateFormate }}</p>
            <p class="goDetails">查看详情 >></p>
          </div>
        </div>
      </div>
    </div>
    <el-row class="pagination">
      <el-col :span="24">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        ></el-pagination>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      newsDatas: [],
      current: 1,
      pageSize: 10,
      totalPage: null,
      activeCloumnId: null,
      activeCloumnName: null
    };
  },
  mounted() {},
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getNewsListDatas(this.activeCloumnId);
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getNewsListDatas(this.activeCloumnId);
    },
    getNewsListDatas(id, name) {
      this.activeCloumnId = id;
      this.activeCloumnName = name;
      protocolFwd.param_getContentTitleList.param.page = this.current - 1;
      protocolFwd.param_getContentTitleList.param.size = this.pageSize;
      protocolFwd.param_getContentTitleList.param.columnId = this.activeCloumnId;
      http.getRes(protocolFwd.param_getContentTitleList).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.newsDatas = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    goNewsDetails(id) {
      this.$router.push({
        name: "newsDetails",
        params: {
          id: id
        }
      });
    }
  }
};
</script>
<style scoped>
.subPage {
  padding: 0 15px;
}
.subPage .subPageTitle {
  overflow: hidden;
}
.subPage .subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPage .subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.newsList .newsItem {
  position: relative;
  padding: 10px 0;
  line-height: 25px;
  border-bottom: 1px dashed #cccccc;
  overflow: hidden;
  cursor: pointer;
}
.newsList .newsItem .newsImage {
  float: left;
  margin-right: 15px;
  width: 200px;
  height: 130px;
}
.newsList .newsItem .newsImage img {
  width: 100%;
  height: 100%;
}
.newsList .newsItem .newsTitle {
  font-size: 16px;
  font-weight: 700;
}
.newsList .newsItem .newsDesc {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #9999a2;
}
.newsList .newsItem .newsRemark {
  position: absolute;
  left: 215px;
  bottom: 10px;
  color: #9999a2;
}
.newsList .newsItem .goDetails {
  position: absolute;
  bottom: 10px;
  right: 0;
  color: #f56c6c;
}
.pagination {
  margin-top: 15px;
}
</style>
